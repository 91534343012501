import React from "react";
import { StaticQuery, graphql } from "gatsby";
import LinkWorkTo from "../Link/LinkWorkTo";
import LinkWorkFrom from "../Link/LinkWorkFrom";

const MenuTitle = ({ location, item }) => {
  if (location.pathname.replace(/\//g, "") === item.menu_item.uid) {
    return <h1>{item.menu_title}</h1>;
  } else {
    return <h2>{item.menu_title}</h2>;
  }
};

const Menu = ({ isWorkPage, location }) => (
  <StaticQuery
    query={graphql`
      query {
        prismicGlobal {
          data {
            menu {
              menu_title
              menu_item {
                uid
              }
              menu_link {
                url
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { menu } = data.prismicGlobal.data;
      const linkClassList = "link text-base md:text-xl uppercase";
      const activeLinkClassList = "link--active";
      return (
        <div className="space-y-2 mb-10 md:mb-14 lg:mb-24">
          {menu
            .filter(item => item.menu_title)
            .map((item, index) => {
              const link = item.menu_link.url
                ? item.menu_link.url
                : item.menu_item?.uid;

              return (
                <div key={index} className="text-right">
                  {isWorkPage ? (
                    <LinkWorkFrom
                      to={`/${link}`}
                      className={linkClassList}
                      activeClassName={activeLinkClassList}
                      partiallyActive
                    >
                      <MenuTitle item={item} location={location} />
                    </LinkWorkFrom>
                  ) : (
                    <LinkWorkTo
                      to={`/${link}`}
                      className={linkClassList}
                      activeClassName={activeLinkClassList}
                      partiallyActive
                    >
                      <MenuTitle item={item} location={location} />
                    </LinkWorkTo>
                  )}
                </div>
              );
            })}
        </div>
      );
    }}
  />
);

export default Menu;
