import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Image from "gatsby-image";
import Layout from "../components/Layout";
import Menu from "../components/Menu";

const ContactTemplate = ({ pageContext, location }) => {
  const [atLarge, setAtLarge] = useState(null);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 1024px)");
    function handleTabletChange(e) {
      if (e.matches) {
        setAtLarge(true);
      } else {
        setAtLarge(false);
      }
    }
    mediaQuery.addListener(handleTabletChange);
    handleTabletChange(mediaQuery);
    return () => {
      mediaQuery.removeListener(handleTabletChange);
    };
  }, []);

  const { data: templateData, global: globalData } = pageContext;

  return (
    <Layout
      seoTitle={templateData.meta_title || templateData.title || "Contact"}
      seoDescription={templateData.meta_description}
      location={location}
    >
      <div className="pt-hh px-ogs pb-ogs">
        <div className="flex flex-wrap -ml-gs -mt-8">
          <div className="w-full lg:w-5/12 pl-gs pt-8">
            <div className="lg:sticky lg:top-hh lg:h-panel">
              {!atLarge && <Menu location={location} />}
              {templateData.image.fluid && (
                <React.Fragment>
                  <Image
                    className="hidden lg:block h-full w-full"
                    placeholderStyle={{ display: "none" }}
                    fluid={{
                      ...templateData.image.fluid,
                      aspectRatio: 0
                    }}
                    loading="eager"
                  />
                  <Image
                    className="block lg:hidden w-full"
                    placeholderStyle={{ display: "none" }}
                    fluid={templateData.image.fluid}
                    loading="eager"
                  />
                </React.Fragment>
              )}
            </div>
          </div>
          <div className="w-full lg:w-7/12 pl-gs pt-8">
            <div className="h-full flex flex-col justify-between">
              {atLarge && <Menu location={location} />}
              <div className="text-base md:text-lg lg:text-xl leading-relaxed space-y-8 lg:space-y-ogs">
                {templateData.body.html && (
                  <div
                    className="space-y-4 lg:space-y-6"
                    dangerouslySetInnerHTML={{
                      __html: templateData.body.html
                    }}
                  />
                )}
                <div className="space-y-2">
                  {globalData.phone && (
                    <div>
                      <a className="link" href={`tel:${globalData.phone}`}>
                        {globalData.phone}
                      </a>
                    </div>
                  )}
                  {globalData.email && (
                    <div>
                      <a className="link" href={`mailto:${globalData.email}`}>
                        {globalData.email}
                      </a>
                    </div>
                  )}
                  {globalData.link_heading && globalData.link.url && (
                    <div>
                      <a className="link" href={globalData.link.url}>
                        {globalData.link_heading}
                      </a>
                    </div>
                  )}
                </div>
                <div className="md:flex flex-wrap justify-between items-end space-y-8">
                  {globalData.address.html && (
                    <div
                      className="space-y-4 lg:space-y-6 pr-gs"
                      dangerouslySetInnerHTML={{
                        __html: globalData.address.html
                      }}
                    />
                  )}
                  <div className="flex flex-wrap text-sm md:text-base -ml-4">
                    {globalData.social_links
                      .filter(
                        item => item.social_link.url && item.social_link_title
                      )
                      .map((item, index) => {
                        return (
                          <div className="pl-4" key={index}>
                            <a
                              href={item.social_link.url}
                              className="hover:text-grey transition-colors duration-200"
                              target="_blank"
                              rel="noreferrer"
                            >
                              {item.social_link_title}
                            </a>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

ContactTemplate.propTypes = {
  location: PropTypes.object.isRequired
};

export default ContactTemplate;
